import React from 'react'
import { connect } from 'react-redux'
import Paper from '@material-ui/core/Paper'
import styled from 'styled-components'
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Permissions from './Permissions'
import { Button, CircularProgress, IconButton } from '@material-ui/core';
import axios from 'axios'
import DeleteIcon from '@material-ui/icons/Delete';
import ProtectedButton from '../../actions/protectedButtons';
import AssignedEntitiesComponent from './AssignedEntitiesComponent'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import { setUser } from '../../actions/connectionActions'
import CachedIcon from '@material-ui/icons/Cached';

const statesList = [
  {
    "name": "None",
    "abbreviation": "NN"
  },
  {
      "name": "Alabama",
      "abbreviation": "AL"
  },
  {
      "name": "Alaska",
      "abbreviation": "AK"
  },
  {
      "name": "American Samoa",
      "abbreviation": "AS"
  },
  {
      "name": "Arizona",
      "abbreviation": "AZ"
  },
  {
      "name": "Arkansas",
      "abbreviation": "AR"
  },
  {
      "name": "California",
      "abbreviation": "CA"
  },
  {
      "name": "Colorado",
      "abbreviation": "CO"
  },
  {
      "name": "Connecticut",
      "abbreviation": "CT"
  },
  {
      "name": "Delaware",
      "abbreviation": "DE"
  },
  {
      "name": "District Of Columbia",
      "abbreviation": "DC"
  },
  {
      "name": "Federated States Of Micronesia",
      "abbreviation": "FM"
  },
  {
      "name": "Florida",
      "abbreviation": "FL"
  },
  {
      "name": "Georgia",
      "abbreviation": "GA"
  },
  {
      "name": "Guam",
      "abbreviation": "GU"
  },
  {
      "name": "Hawaii",
      "abbreviation": "HI"
  },
  {
      "name": "Idaho",
      "abbreviation": "ID"
  },
  {
      "name": "Illinois",
      "abbreviation": "IL"
  },
  {
      "name": "Indiana",
      "abbreviation": "IN"
  },
  {
      "name": "Iowa",
      "abbreviation": "IA"
  },
  {
      "name": "Kansas",
      "abbreviation": "KS"
  },
  {
      "name": "Kentucky",
      "abbreviation": "KY"
  },
  {
      "name": "Louisiana",
      "abbreviation": "LA"
  },
  {
      "name": "Maine",
      "abbreviation": "ME"
  },
  {
      "name": "Marshall Islands",
      "abbreviation": "MH"
  },
  {
      "name": "Maryland",
      "abbreviation": "MD"
  },
  {
      "name": "Massachusetts",
      "abbreviation": "MA"
  },
  {
      "name": "Michigan",
      "abbreviation": "MI"
  },
  {
      "name": "Minnesota",
      "abbreviation": "MN"
  },
  {
      "name": "Mississippi",
      "abbreviation": "MS"
  },
  {
      "name": "Missouri",
      "abbreviation": "MO"
  },
  {
      "name": "Montana",
      "abbreviation": "MT"
  },
  {
      "name": "Nebraska",
      "abbreviation": "NE"
  },
  {
      "name": "Nevada",
      "abbreviation": "NV"
  },
  {
      "name": "New Hampshire",
      "abbreviation": "NH"
  },
  {
      "name": "New Jersey",
      "abbreviation": "NJ"
  },
  {
      "name": "New Mexico",
      "abbreviation": "NM"
  },
  {
      "name": "New York",
      "abbreviation": "NY"
  },
  {
      "name": "North Carolina",
      "abbreviation": "NC"
  },
  {
      "name": "North Dakota",
      "abbreviation": "ND"
  },
  {
      "name": "Northern Mariana Islands",
      "abbreviation": "MP"
  },
  {
      "name": "Ohio",
      "abbreviation": "OH"
  },
  {
      "name": "Oklahoma",
      "abbreviation": "OK"
  },
  {
      "name": "Oregon",
      "abbreviation": "OR"
  },
  {
      "name": "Palau",
      "abbreviation": "PW"
  },
  {
      "name": "Pennsylvania",
      "abbreviation": "PA"
  },
  {
      "name": "Puerto Rico",
      "abbreviation": "PR"
  },
  {
      "name": "Rhode Island",
      "abbreviation": "RI"
  },
  {
      "name": "South Carolina",
      "abbreviation": "SC"
  },
  {
      "name": "South Dakota",
      "abbreviation": "SD"
  },
  {
      "name": "Tennessee",
      "abbreviation": "TN"
  },
  {
      "name": "Texas",
      "abbreviation": "TX"
  },
  {
      "name": "Utah",
      "abbreviation": "UT"
  },
  {
      "name": "Vermont",
      "abbreviation": "VT"
  },
  {
      "name": "Virgin Islands",
      "abbreviation": "VI"
  },
  {
      "name": "Virginia",
      "abbreviation": "VA"
  },
  {
      "name": "Washington",
      "abbreviation": "WA"
  },
  {
      "name": "West Virginia",
      "abbreviation": "WV"
  },
  {
      "name": "Wisconsin",
      "abbreviation": "WI"
  },
  {
      "name": "Wyoming",
      "abbreviation": "WY"
  }
]
const Container = styled(Paper)`
  margin: 10px;
  min-height: 85vh;
  color: #555;
  font-size: 18px;
  padding: 10px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
`;

const Item = styled.div`
  display: flex;
`;

const RolesContainer = styled(Box)`
  display: flex;
  margin-top: 25px;
  margin-bottom: 15px;
  max-width: 50%;
`;

const RolesList = styled(Box)`
  display: flex;
  align-self: stretch;
  font-size: 16px;
`;

const PermissionsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  width: 50%
`;

const Topbar = styled(Box)`
  display: flex;
  flex-direction: row;
  font-size: 16px;
  width: 80%;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
`;

const HeaderLeftSide = styled(Box)`
display: flex;
justify-content: flex-start;
align-items: flex-start;
flex-grow: 1;
`;

const HeaderRightSide = styled(Box)`
display: flex;
justify-content: flex-end;
align-items: flex-end;
flex-grow: 1;

`;

const defaultAssignedState = statesList[0]

const roles = [
  'User', 'Office', 'Supervisor', 'Admin'
]

const UserDetail = (props) => {
  // console.log('User Details: ', props)
  const [profile, setProfile] = React.useState({})
  const [state, setState] = React.useState({
    userType: 'User',
    permissions: [],
    profile: {},
    name: '',
    firstName: '',
    lastName: '',
    isActive: true,
    assignedState: {
      name: 'None',
      abbreviation: 'NN'
    }
  })
  const [loading, setLoading] = React.useState(false)
  const [entities, setEntities] = React.useState([])


  const inputRef = React.useRef('')

  const getProfile = async (id) => {
    const userData = await axios.get(`${props.url}/api/user/userProfile?id=${id}`)
    // console.log('Got our profile: ', userData)
    if (userData && userData.data && userData.data.user && userData.data.user._id) {
      const profile = userData.data.user
      // console.log('Profile to set: ', profile)
      setProfile(profile)
      // If you are on your own profile update it in real time
      if (userData.data.user._id === props.user._id) props.setUser(profile)
      // inputRef.value = profile.name
      // console.log('Set User type', profile)
      let userType = roles[0]
      let index = roles.findIndex(role => (role === profile.userType))
      if (index > -1) userType = roles[index]
      // console.log('Set index', index)
      // console.log('Usertype: ', userType)
      let profileAssignedState = null
      if (profile && profile.assignedState && profile.assignedState.name) profileAssignedState = profile.assignedState.name
      let assignedState = statesList[0]
      let assignedStateIndex = statesList.findIndex(stateItem => (stateItem.name === profileAssignedState ))
      if (assignedStateIndex > -1) assignedState = statesList[assignedStateIndex]
      setState({...state, profile: profile, isActive: profile.active, name: profile.name, firstName: profile.firstName, lastName: profile.lastName, permissions: profile.permissions, userType: userType, entities: profile.entities, assignedState: assignedState })
    }
    if (userData && userData.data.entities && userData.data.entities.length) {
      // console.log('Set entities', userData.data.entities)
      setEntities(userData.data.entities)
    } else {
      // console.log('Set blank entities')
      setEntities([])
    }
  }

  React.useEffect(() => {
    // console.log('not related:', props.match.params)
    if (props.match.params.userId) getProfile(props.match.params.userId)
    // if (props.location && props.location.state && props.location.state.profile) {
    //   setProfile(props.location.state.profile)
    //   if (props.location.state.profile.userType && props.location.state.profile.permissions) {
    //     setState({...state, permissions: props.location.state.profile.permissions, userType: props.location.state.profile.userType})
    //   }
    // }
  },[])

  // React.useEffect(() => {
  //   console.log('PROFILE CHANGED: ', profile)
  //   setState({...state, name: profile.name})

  // }, [profile])

  const handleSave = async () => {
    // console.log('Save this employee', profile)
    // console.log(state)
    // console.log('Entiites Assigned: ', entities)
    // profile.entities = entities
    profile.name = state.name
    profile.active = state.isActive
    profile.userType = state.userType
    profile.assignedState = state.assignedState
    // const data = {
    //   // state,
    //   profile,
    //   // user: props.user
    // }
    // console.log(profile)
    axios.post(`${props.url}/api/user/update`, profile).then((response) => {
      // console.log(response)
      if (response.data._id) {
        // console.log('Set Permissions/Profile', response.data)
        // setProfile(response.data)
        // setState({...state, permissions: response.data.permissions})
        props.notification({
          type: 'success',
          title: 'User Profile Updated!',
          message: 'Successfully saved those changes.'
        })
      }
    }).catch(e => {
      console.log('Error saving', e)
      props.notification({
        type: 'warning',
        title: 'Failed to Update Profile',
        message: `We're Sorry, We Were Unable To Update This User. Perhaps you don't have the right permissions?`
      })
    })

  }

  const handlePermissionsUpdate = (d) => {
    // console.log('Permissions update: ', d)
    let ourProfile = profile
    ourProfile.permissions = d
    setProfile(ourProfile)
    setState({...state, permissions: d})
  }

  const handleDeleteUser = () => {
    // console.log('Delete This User')
    const data = {
      user: props.user,
      profile: profile
    }
    setLoading(true)
    axios.post(`${props.url}/api/user/remove`, data).then((response) => {
      console.log(response)
      setLoading(false)
      if (response.data.success) {
        console.log('Remove /Profile', response.data)
        props.notification({
          type: 'success',
          title: 'User Deleted',
          message: `We are sad to see ${profile.name} go, but what's done is done.`
        })
        props.history.goBack()
      } else {
        props.notification({
          type: 'warning',
          title: 'Unable to Delete User',
          message: `Perhaps you don't have the proper permissions?`
        })
      }
    }).catch(e => {
      console.log('Error deleting', e)
    })
  }

  const DeleteButton = () => {
    // console.log('Shoe delete button ro not? ', props.user)
    // let toShow = false
    // props.user.permissions.map(perm => {
    //   console.log(perm)
    //   if (perm.type === 'Users') {
    //     if (perm.delete) {
    //       toShow = true
    //     }
    //   }
    // })
    // console.log('To Show Delet Button: ', toShow)
    // if (toShow) {
      if (loading) {
        return (
          <Button
          variant="contained"
          style={{backgroundColor: '#ed4545'}}
          size="small"
          startIcon={<DeleteIcon />}
          disabled
          >
            <CircularProgress color="primary" size={ 24 } />
          </Button>
        )
  
        } else {
        return (
          <Button
          variant="contained"
          style={{backgroundColor: '#ed4545'}}
          size="small"
          startIcon={<DeleteIcon />}
          onClick={() => { if (window.confirm('Are you sure you wish to delete this user? This is not reversible.')) handleDeleteUser() } }
        >
          { loading ? 'DELETING' : 'Delete' }</Button>     
        )
      }
}
const saveEntities = (entities) => {
  // console.log('This user should be assigned to: ', entities)
  setState({...state, entities})
  let newProfile = profile
  newProfile.entities = entities
  // console.log('Updated PRofile', newProfile)
  setProfile(newProfile)
}

const updateDefaultEntity = (entity, entities) => {
  // console.log('Update Default Entity: ')
  let newProfile = profile
  newProfile.entities = entities
  newProfile.defaultEntity = entity
  // console.log('Updated PRofile', newProfile)
  setProfile(newProfile)
  setState({...state, profile: newProfile, name: profile.name})
}

const handleBack = (e) => {
  e.preventDefault()
  props.history.goBack()
}

const updateName = (e) => {
  let name = state.name
  if (e.target.id === 'firstName') {
    name = `${e.target.value} ${state.lastName}`
  }
  if (e.target.id === 'lastName') {
    name = `${state.firstName} ${e.target.value}`
  }
  setState({...state, [e.target.id]: e.target.value, name: name })
  // inputRef.current[e.target.id] = e.target.value
}

  return (
    <div>
      <Container>
        <Topbar>
          <HeaderLeftSide>
            <Button
            variant="contained"
            size="small"
            startIcon={<ArrowBackIcon />}
            onClick={handleBack}
            >
              Back
            </Button>         
          </HeaderLeftSide>
          <HeaderRightSide>
            <ProtectedButton type="Users" kind="delete" perms={props.perms}>
              <DeleteButton />
            </ProtectedButton>
            

          </HeaderRightSide>
        </Topbar>
        <Topbar>
          <HeaderLeftSide>
            <Item ><h2>{state.name}</h2></Item>
          </HeaderLeftSide>
          <HeaderRightSide>
            <Button
              variant="contained"
              color="primary"
              size="small"
              // style={{ textTransform: "none", padding: "14px 0px" }} 
              // onClick={handleAddLineItem}
              startIcon={<CachedIcon />}
              >Reset PW</Button>
          </HeaderRightSide>
        </Topbar>
       
        <Item>
          First Name: &nbsp;&nbsp;
          <TextField
            id="firstName"
            // label="Name"
            type="text"
            value={state.firstName}
            // defaultValue={profile.name || ''}
            // ref={inputRef}
            // className={classes.textField}
            // InputLabelProps={{
            //   shrink: true,
            // }}
            onChange={updateName}
            // fullWidth
          />
        </Item>
        <Item>
          Last Name: &nbsp;&nbsp;
          <TextField
            id="lastName"
            // label="Name"
            type="text"
            value={state.lastName}
            // defaultValue={profile.name || ''}
            // ref={inputRef}
            // className={classes.textField}
            // InputLabelProps={{
            //   shrink: true,
            // }}
            onChange={updateName}
            // fullWidth
          />
        </Item>
        <Item>Email Address: {profile.email}</Item>
        <Item>Username: {profile.username}</Item>
        <RolesContainer>
          <FormControl fullWidth>
            <ProtectedButton type="Users" kind="update" perms={props.perms}>
            <FormControl component="fieldset" >
                  <FormLabel component="legend">Active User</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={state.isActive}
                      onChange={ e => setState({...state, isActive: !state.isActive }) }
                      name="Active" />}
                      label="Active"
                    />
                  </FormGroup>
                </FormControl>
          </ProtectedButton>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="user-type">User Type</InputLabel>
            <Select
              labelId="user-type"
              id="user-type"
              value={state.userType}
              onChange={(e) => setState({...state, userType: e.target.value })}
            >
              {/* <MenuItem value={'User'}>User</MenuItem>
              <MenuItem value={'Admin'}>Admin</MenuItem> */}
              {
                roles.map((role, index) => {
                  return (
                    <MenuItem key={index} value={role}>{role}</MenuItem>
                  )
                })
              }
            </Select>
            <FormHelperText>Type of User (Grants Basic Permissions & Views)</FormHelperText>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="assigned-state">Assigned State</InputLabel>
            <Select
              labelId="assigned-state"
              id="assigned-state"
              value={state.assignedState}
              onChange={(e) => setState({...state, assignedState: e.target.value })}
            >

              {
                statesList.map((state, index) => {
                  return (
                    <MenuItem key={index} value={state}>{state.name}</MenuItem>
                  )
                })
              }
            </Select>
            <FormHelperText>Assigned State</FormHelperText>
          </FormControl>
        
          
        </RolesContainer>
        <PermissionsContainer>
            Please Choose Specific Permissions for this User:
            <Permissions profile={profile} updateParent={handlePermissionsUpdate} />
        </PermissionsContainer>
        <Button
          size="medium"
          color="primary"
          variant="contained"
          onClick={handleSave}
          >Save</Button>
      </Container>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification,
    perms: state.perms
  }
}

const mapDispatchToProps = (dispatch) => {
  // console.log('DISTPACTH: ', dispatch)
  return {
    setUser: (user) => { dispatch(setUser(user)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDetail)