const initState = {
  user: null,
  authenticated: false,
  notification: null,
  paypal: null,
  theme: 'dark',
  perms: []
}

const rootReducer = (state = initState, action) => {
  console.log('Reducer: ', action)
  switch (action.type) {
    case 'setUrl':
      return {
        ...state,
        url: action.url
      }
    case 'setPortalUrl':
      return {
        ...state,
        portalurl: action.url
      }
    case 'setNotifications':
      return {
        ...state,
        notification: action.notification
      }
    case 'setAuth':
      return {
        ...state,
        authenticated: action.authenticated
      }
    case 'setUser':
      return {
        ...state,
        user: action.user
      }
    case 'setPaypal':
      return {
        ...state,
        paypal: action.paypal
      }
    case 'setTheme':
      return {
        ...state,
        theme: action.theme
      }
    case 'setPerms':
      // console.log('Set Permissions: ', action)
      return {
        ...state,
        perms: action.perms
      }
    default:
      return state
  }
}

export default rootReducer
