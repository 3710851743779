export const setUrl = (url) => {
  return {
    type: 'setUrl',
    url
  }
}

export const setPortalUrl = (url) => {
  console.log('Set Portal URL: ', url)
  return {
    type: 'setPortalUrl',
    url
  }
}

export const setAuth = (authenticated) => {
  return {
    type: 'setAuth',
    authenticated
  }
}

export const setNotifications = (notification) => {
  return {
    type: 'setNotifications',
    notification
  }
}

export const setUser = (user) => {
  return {
    type: 'setUser',
    user
  }
}

export const setPaypal = (paypal) => {
  return {
    type: 'setPaypal',
    paypal
  }
}

export const setTheme = (theme) => {
  return {
    type: 'setTheme',
    theme
  }
}

export const setUsers = (data) => {
  // console.log('SET USERS CALLED!!!', data)
  return {
    type: 'setUsers',
    data
  }
}

export const setTasks = (data) => {
  // console.log('SET EMPLOYEES CALLED!!!', data)
  return {
    type: 'setTasks',
    data
  }
}

export const setPerms = (perms) => {
  return {
    type: 'setPerms',
    perms
  }
}
