import React, { Component } from 'react'
import styles from './profile.module.scss'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card,
  Grid,
  Chip,
  CardContent,
  Typography,
  Paper
} from '@material-ui/core'
import axios from 'axios'
import moment from 'moment'
import RealTimeDataTable from './RealTimeDataTable'
import DownloadsDataTable from './DownloadsDataTable'
import DownloadDetailsTable from './DownloadDetailsTable'
import KansasDownloadDetails from './KansasDownloadDetails'

const VehicleDetails = (props) => {
  // console.log(props)
  const client = props.client
  return (
    <div>
      <Typography color="textSecondary">
        Vehicle Info
      </Typography>
      <Typography component="p" gutterBottom>
        <div>
          { client.vehicleColor } { client.vehicleYear } { client.vehicleMake } { client.vehicleModel }
        </div>
        <div>
          {client.VIN}
        </div>
        <div>
          {client.plateNum}
        </div>
      </Typography>
    </div>
  )
}

const StateComplianceNotes = ({ client }) => {
  // console.log('CLIENT:', client)
  const notes = client.notes
  // console.log('Notes; ', notes)
  if (notes && notes.length) {
    return (
      <div style={{ maxHeight: 350, overflowY: 'scroll', padding: 5 }}>
        {
          notes.map((note, index) => {
            return (
              
                <div key={index} style={{ border: '2px #aaa solid', borderRadius: 6, display: 'flex', margin: 5, padding: 8, flexDirection: 'column' }}>
                  <div style={{ display: 'flex', flexGrow: 1 }}>
                    {note.content}
                  </div>
                  <div style={{ display: 'flex', flexGrow: 1, alignContent: 'flex-end', justifyContent: 'flex-end' }}>
                    {note.author}
                  </div>
                  <div style={{ display: 'flex', flexGrow: 1, alignContent: 'flex-end', justifyContent: 'flex-end' }}>
                    {moment(note.created).format('MM/DD/YYYY')}
                  </div>
                </div>
            )
          })
        }
      </div>
    )
  } else {
    return null
  }
}

const openDetails = function(dets) {
  // console.log('Open Details', dets)
}
function DataDownloadDetails (d) {
  // console.log('Data download: ', d)
  const data = d.data
  const index = d.index
  const summary = data.summary[0]
  let  numberOfRecords = 0
  if (summary && summary.allData && summary.allData.length > 0) numberOfRecords = summary.allData.length
  let html = (
    <Card className={ styles.dataDetailsCard }>
      <CardContent >
        <Grid container spacing={1}>
          <Grid item xs={4}>
            Download #{ index + 1 }
          </Grid>
          <Grid item xs={8}>
          { moment(data.created).format('ddd MMM DD, YYYY [at] hh:mma') } 
          </Grid>
          <Grid item xs={12}>
            <b>Total Records: </b> { numberOfRecords }
          </Grid>
          <Grid item xs={12}>
            <b>Tests Passed: </b> { summary.passedTests || 0 }
          </Grid>
          <Grid item xs={12}>
            <b>Tests Failed: </b> { summary.failedTests || 0 }
          </Grid>
          <Grid item xs={12}>
            { summary.tamperAttempts > 0 ? <Chip variant="outlined" size="small" label={ summary.tamperAttempts + ' Tamper Attempts' } /> : '' }
            { summary.circumventionAttempts > 0 ? <Chip variant="outlined" size="small" label={ summary.circumventionAttempts + ' Circumvention Attempts' } /> : '' }
          </Grid>
        </Grid>
        <Button
          onClick={ (e) => openDetails(index) }
          className={ styles.button }
          variant="contained"
          color="secondary"
        >
          View Download Details
        </Button>
      </CardContent>
    </Card>
  )
  return html
}
class CustomerProfile extends Component {
  constructor(props) {
    super(props)
    console.log('Customer Profile Props: ', props)
    const customer = props.history.location.state.customer
    let profileImage = null
    if (customer && customer.profileImage) {
      profileImage = props.portalurl
      let restOfImg = customer.profileImage
      let imgString = profileImage + '/'
      if (restOfImg && restOfImg.length > 5) {
        console.log('We have a profile Image')
        let str = restOfImg.split('public/')
        console.log(str[1])
        imgString+= str[1]
      }
      profileImage = imgString
    }
    console.log('Profile Image: ', profileImage)
    this.state = {
      open: false,
      customer: customer || {},
      profileImage: profileImage
    }
    console.log('This.state: ', this.state)
  }

  makePrettyNumber = (phoneNumberString) => {
    let cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }
    return phoneNumberString
  }

  removeDialogClick = () => {
    this.setState({
      open: this.state.open ? false : true
    })
  }

  render() {
    return (
      <div className={ styles.profile }>
        <Card className={ styles.card }>
          <CardContent>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant="h5" component="h2" gutterBottom>
                Customer Profile
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography color="textSecondary">
                Name
              </Typography>
              <Typography component="p" gutterBottom>
                { this.state.customer.firstName } { this.state.customer.lastName }
              </Typography>
              <Typography color="textSecondary">
                Email
              </Typography>
              <Typography component="p" gutterBottom>
                { this.state.customer.offenderEmail }
              </Typography>
              <Typography color="textSecondary">
                Driver License
              </Typography>
              <Typography component="p" gutterBottom>
                { this.state.customer.driverNumber }
              </Typography>
              <VehicleDetails client={this.state.customer} />
              <Typography color="textSecondary">
                Telephone
              </Typography>
              <Typography component="p" gutterBottom>
                { this.makePrettyNumber(this.state.customer.mainPhone) }
              </Typography>
              <Typography color="textSecondary">
                Location
              </Typography>
              <Typography component="p" gutterBottom>
                { this.state.customer.billingCity },  { this.state.customer.billingState }
              </Typography>
              <Typography color="textSecondary">
                Install Date
              </Typography>
              <Typography component="p" gutterBottom>
                { this.state.customer.deployedDate ? moment(this.state.customer.deployedDate).format('MM/DD/YYYY') : 'Pending Installation' }
              </Typography>
            </Grid>
            <Grid item xs={6}>
              { this.state.profileImage ? <img src={this.state.profileImage} className={ styles.profileimage }></img> : 'No Profile Image Available' }
            </Grid>
          </Grid>
        </CardContent>
        </Card>
        <Card style={{ margin: '10px'}}>
          <CardContent>
            <Typography style={{ color: '#eee' }} variant="h5" component="h2" gutterBottom>
             State Compliance Notes
            </Typography>
            <StateComplianceNotes client={this.state.customer || {}} />
          </CardContent>
        </Card>
        <Card style={{ margin: '10px'}}>
          <CardContent>
          <Typography variant="h5" component="h2" gutterBottom>
              Data Download Details
          </Typography>
            <KansasDownloadDetails data={this.state.customer.downloads} />
            {/* <DownloadDetailsTable data={this.state.customer.downloads} ></DownloadDetailsTable>
            <DownloadsDataTable data={this.state.customer.downloads} /> */}
          </CardContent>
        </Card>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    portalurl: state.portalurl,
    user: state.user,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(CustomerProfile)
