// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".profile_profile__2Pg7z {\n  margin: 20px; }\n\n.profile_button__1HMfu {\n  margin-right: 20px !important; }\n\n.profile_link__3nPrN {\n  color: inherit;\n  text-decoration: none;\n  cursor: inherit; }\n\n.profile_card__1y0UG {\n  width: 80%;\n  margin: auto; }\n\n.profile_profileimage__sQFbF {\n  width: 50%; }\n\n.profile_dataDetailsCard__T4h2W {\n  background-color: #eee;\n  color: #444;\n  padding: 3px;\n  width: 95%;\n  margin: 5px; }\n\n.profile_dataDetailsHeader__3uZMX {\n  font-size: 18px; }\n\n.profile_active__9e7pp {\n  color: #521; }\n\n.profile_filterArea__1z1fT {\n  cursor: pointer;\n  color: #521; }\n", ""]);
// Exports
exports.locals = {
	"profile": "profile_profile__2Pg7z",
	"button": "profile_button__1HMfu",
	"link": "profile_link__3nPrN",
	"card": "profile_card__1y0UG",
	"profileimage": "profile_profileimage__sQFbF",
	"dataDetailsCard": "profile_dataDetailsCard__T4h2W",
	"dataDetailsHeader": "profile_dataDetailsHeader__3uZMX",
	"active": "profile_active__9e7pp",
	"filterArea": "profile_filterArea__1z1fT"
};
module.exports = exports;
