import React from 'react'
import { Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Grid } from '@material-ui/core'
import moment from 'moment'
import DownloadDetailsTable from './DownloadDetailsTable'

const DownloadDetails = (props) => {
  console.log('Download Details', props)
  const [showDownloadDetails, setShowDownloadDetails] = React.useState(false)
  const [downloadData, setDownloadData] = React.useState({})
  const [numberOfRecords, setNumberOfRecords] = React.useState(0)
  const [ourSummary, setOurSummary] = React.useState({})
  React.useEffect(() => {
    console.log('Use effectset data download: ', props)
    if (props && props.open === true) setShowDownloadDetails(true)
    if (props && props.data && props.data._id) {
      console.log('SET THIS SHIT!!!')
      setDownloadData(props.data)
      console.log('Download Data:', downloadData)
      if (props.data.summary && props.data.summary.length > 0) {
        let summary = props.data.summary[0]
        if (summary && summary.allData && summary.allData.length > 0) {
          let numRecs = summary.allData.length
          setNumberOfRecords(numRecs)
        }
        console.log('Summary: ', summary)
        setOurSummary(summary)
      }
      
//       allData: (137) [{…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, …]
// duiLevel: 0
// failedTests: 1
// highBAC: {time: "18:58:03", date: "05/01/2020", bac: 0.033}
// notes: "..."
// passedTests: 26
// retestsMissed: 1
// tamperAttempts: 8
// vioData: [{…}]
// violations: 2
    }
  }, [props.data])

  
  return (
    <Dialog
      open={ showDownloadDetails }
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullScreen={true}
    >
      <DialogTitle id="alert-dialog-title">Download Details - { moment(downloadData.created).format('ddd MMM DD, YYYY [at] hh:mma') }  </DialogTitle>
      <DialogContent>
        <DownloadDetailsTable data={downloadData}></DownloadDetailsTable>
      </DialogContent>
      <DialogActions>
        
        <Button onClick={(() => setShowDownloadDetails(false))} color="secondary" autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default(DownloadDetails)