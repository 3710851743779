// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".home_home__3gHdI {\n  margin: 20px; }\n\n.home_card__1mt3S {\n  width: 80%;\n  text-align: center;\n  margin: auto; }\n", ""]);
// Exports
exports.locals = {
	"home": "home_home__3gHdI",
	"card": "home_card__1mt3S"
};
module.exports = exports;
