// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".registration_registration__1EVUi {\n  margin: 20px;\n  text-align: left; }\n\n.registration_title__2aa9w {\n  padding-left: 30px;\n  padding-top: 20px; }\n\n.registration_content__3qk1b {\n  margin: 10px;\n  padding: 20px;\n  padding-top: 0; }\n\n.registration_logo__1mi88 {\n  margin-left: 0;\n  margin-right: 0; }\n\nbutton {\n  margin-top: 20px !important; }\n", ""]);
// Exports
exports.locals = {
	"registration": "registration_registration__1EVUi",
	"title": "registration_title__2aa9w",
	"content": "registration_content__3qk1b",
	"logo": "registration_logo__1mi88"
};
module.exports = exports;
