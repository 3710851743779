import React from 'react'
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import { connect } from 'react-redux'
import { fade, makeStyles } from '@material-ui/core/styles';
import styles from './profile.module.scss'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Grid } from '@material-ui/core'
// import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
// import Checkbox from '@material-ui/core/Checkbox';
// import IconButton from '@material-ui/core/IconButton';
// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
// import DeleteIcon from '@material-ui/icons/Delete';
// import FilterListIcon from '@material-ui/icons/FilterList';
import moment from 'moment'
// import Chip from '@material-ui/core/Chip';
// import { Link } from 'react-router-dom'
// import InputBase from '@material-ui/core/InputBase';
// import SearchIcon from '@material-ui/icons/Search';
import DownloadDetailsModal from './DownloadDetailsModal'
// function createData(name, status, created, lastactivity, tourbooked) {
//   return { name, status, created, lastactivity, tourbooked };
// }






function desc(a, b, orderBy, isDate) {
  // console.log('desc: ', a)
  // console.log('desc b: ', b)
  if (isDate) {
    if (b[orderBy] < a[orderBy] || b[orderBy] == null) {
      // console.log('B is < A')
      return -1;
    }
    if (b[orderBy] > a[orderBy] || a[orderBy] == null) {
      // console.log('B is > A')
      return 1;
    }
    // console.log('B is ? A')
    // console.log('A: ', a[orderBy])
    // console.log('B: ', b[orderBy])
    return 0;
  } else {
    if (b[orderBy] < a[orderBy] || b[orderBy] == null) {
      return -1;
    }
    if (b[orderBy] > a[orderBy] || a[orderBy] == null) {
      return 1;
    }
    return 0;
  }
}

function stableSort(array, cmp) {
  // console.log('Stable sort: ', array)
  // console.log('CMP: ', cmp)
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  if (orderBy==='lastUpdated') {
 // console.log('Last Updated')
    return order === 'desc' ? (a, b) => desc(a, b, orderBy, true) : (a, b) => -desc(a, b, orderBy, true);
  } else {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }
  
}

const headCells = [
  { id: 'line', numeric: true, disablePadding: true, label: 'Line' },
  { id: 'date', numeric: true, disablePadding: true, label: 'Timestamp' },
  { id: 'event', numeric: true, disablePadding: true, label: 'Event' },
  { id: 'state', numeric: false, disablePadding: false, label: 'State' },
  { id: 'bac', numeric: true, disablePadding: false, label: 'BAC' },
  { id: 'unit', numeric: true, disablePadding: false, label: 'Unit' },
  { id: 'photoURL', numeric: true, disablePadding: false, label: 'Photo' }
];

function EnhancedTableHead(props) {
  // console.log('Got our props', props)
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
 // console.log('Sort Property: ', property)
 // console.log('sort event; ', event)
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all leads' }}
          />
        </TableCell> */}
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            // align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

// const useToolbarStyles = makeStyles(theme => ({
//   root: {
//     paddingLeft: theme.spacing(2),
//     paddingRight: theme.spacing(1),
//   },
//   highlight:
//     theme.palette.type === 'light'
//       ? {
//           color: theme.palette.secondary.main,
//           backgroundColor: lighten(theme.palette.secondary.light, 0.85),
//         }
//       : {
//           color: theme.palette.text.primary,
//           backgroundColor: theme.palette.secondary.dark,
//         },
//   title: {
//     flex: '1 1 100%',
//   },
// }));

// const EnhancedTableToolbar = props => {
//   const classes = useToolbarStyles();
//   const { numSelected } = props;

//   return (
//     <Toolbar
//       className={clsx(classes.root, {
//         [classes.highlight]: numSelected > 0,
//       })}
//     >
//       {numSelected > 0 ? (
//         <Typography className={classes.title} color="inherit" variant="subtitle1">
//           {numSelected} selected
//         </Typography>
//       ) : (
//         <Typography className={classes.title} variant="h6" id="tableTitle">
//           Leads
//         </Typography>
//       )}

//       {numSelected > 0 ? (
//         <Tooltip title="Delete">
//           <IconButton aria-label="delete">
//             <DeleteIcon />
//           </IconButton>
//         </Tooltip>
//       ) : (
//         <Tooltip title="Filter list">
//           <IconButton aria-label="filter list">
//             <FilterListIcon />
//           </IconButton>
//         </Tooltip>
//       )}
//     </Toolbar>
//   );
// };

// EnhancedTableToolbar.propTypes = {
//   numSelected: PropTypes.number.isRequired,
// };

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  filterArea: {
    cursor: 'pointer'
  }
}));

function EnhancedTable(props) {
  console.log('Download Details Data: ', props)
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('-created');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [searchValue, setsearchValue] = React.useState('');
  const [numberOfRecords, setNumberOfRecords] = React.useState(0)
  // const [searchMatches, setsearchMatches] = React.useState(0);
  const [showDownloadDetails, setShowDownloadDetails] = React.useState(false)
  const [detailsToShow, setDetailsToShow] = React.useState({})
  const [showBigImage, setShowBigImage] = React.useState(false)
  const [bigImageSource, setBigImageSource] = React.useState('')
  const [summary, setSummary] = React.useState({});
  const [allData, setAllData] = React.useState([]);
  const [activeFilter, setActiveFilter] = React.useState({})
  // setLeads(props.leads)
  // let allData = []
  let numRecs = 0
  // if (props.data && props.data.summary && props.data.summary[0].allData) {
  //// console.log(props.data.summary)
  //   allData = props.data.summary[0].allData
  //   // let numRecs = props.data.summary[0].allData.length
  //   // setNumberOfRecords(numRecs)
  //   // setSummary(props.data.summary[0])
  // }
  // console.log('Summary: ', summary)
  // setOurSummary(summary)

  // [0]['allData']
  const [rows, setRows] = React.useState(allData || []);
  const [portalurl, setPortalurl] = React.useState(props.portalurl || 'https://iidportal.com');

  React.useEffect(() => {
 // console.log('Use effectset rows: ', props)
    if (props.data && props.data.summary && props.data.summary[0].allData && props.data.summary[0].allData.length) {
      setRows(props.data && props.data.summary && props.data.summary[0].allData || []);
      setAllData(props.data && props.data.summary && props.data.summary[0].allData || [])
      setNumberOfRecords(props.data.summary[0].allData.length || 0)
      setSummary(props.data.summary[0] || {})
      setPortalurl(props.portalurl)
    }
  }, [props])



  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
 // console.log('Set order by property: ' + property + ' in ' + isDesc + ' order')
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n._id);
      // console.log(newSelecteds)
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name, item) => {
    // const selectedIndex = selected.indexOf(name);
 // console.log('handle click ', name)
 // console.log(item)
    setDetailsToShow(item)
    setShowDownloadDetails(true)
    // console.log('Index: ', selectedIndex)
    // let newSelected = [];

    // if (selectedIndex === -1) {
    //   newSelected = newSelected.concat(selected, name);
    // } else if (selectedIndex === 0) {
    //   newSelected = newSelected.concat(selected.slice(1));
    // } else if (selectedIndex === selected.length - 1) {
    //   newSelected = newSelected.concat(selected.slice(0, -1));
    // } else if (selectedIndex > 0) {
    //   newSelected = newSelected.concat(
    //     selected.slice(0, selectedIndex),
    //     selected.slice(selectedIndex + 1),
    //   );
    // }

    // setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const handleChangeDense = event => {
  //   setDense(event.target.checked);
  // };

  const isSelected = name => selected.indexOf(name) !== -1;

  const emptyRows = 0// rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const filterOut = (r) => {
    // console.log('FIlter out: ', r)
    let string = searchValue
    let newArr = []
    r.filter(o => {
      // console.log('Object:  ', o)
      Object.keys(o).some(k => {
        // console.log(o[k])
        if(o[k]) {
          // console.log('OK: ', o[k])
          let str = o[k].toString().toLowerCase() 
          // console.log(str)
          if (str.includes(string.toLowerCase())) {
            // console.log('Mathc')
            return newArr.push(o)
          } else {
            return null
          }
        } else {
          return null
        }
      })
      // return newArr
    })
    return newArr
    // setsearchMatches(newArr.length)
    // let newArr = r.filter(i => {
    //   if (i.includes('test')) return i
    // })
    // return newArr
    // return r.filter((obj)=>{
    //   return Object.keys(obj).reduce((acc, curr)=>{
    //         return acc || obj[curr] ? obj[curr].toString().toLowerCase().includes(string) : obj[curr];
    //   }, false);
    // })
  }
  const handleChange = (name) => (event) => {
    setsearchValue(event.target.value)
  }

  const openImage = (item) => {
 // console.log('Open: ', item)
    if (item && item.photoURL) {
   // console.log('Open the photo')
      setShowBigImage(true)
      let photoURL = `${portalurl}/${item.photoURL}`
      setBigImageSource(photoURL)
    }
  }

  const filterTable = (e, filterString, kind) => {
  console.log('Filter', filterString)
  console.log('Kind: ', kind)
  if (activeFilter) {
    activeFilter.style = 'color: #fff; font-size: 1em'
    // activeFilter.style = 'fontSize = '1em'
  }
  // , fontSize: 1em'
  setActiveFilter(e.target)
  e.target.style = 'color: #ebcf34; font-size: 1.25em' // , 
  // e.target.style.fontSize = '1.2em'
  // console.log(e.target)
 // console.log(allData.length)
    // let newRows = rows.filter(row => {
    //// console.log(row.event)
    //   if (row.event === "Failed") {
    //     return row
    //   }
    // })
    // console.log('New Rows: ', newRows)
  //   if (filterString) {
  //  console.log('Filter by: event: ', filterString)
  //  // console.log(allData)
  //     let rows2 = allData.filter(row => row.event === filterString)
  //     // console.log(rows2)
  //     setRows(rows2)
  //     setPage(0)
  //   } else {
    console.log('All Data', allData)
      if (kind === 'all') setRows(allData)
      if (kind === 'Pass') setRows(allData.filter(row => (row.result === "Pass" || row.event === "Pass" || row.state === 'Regular Blow' )))
      if (kind === 'Fail') setRows(allData.filter(row => (row.result === "Fail" || row.event === 'Fail' || (row.state === 'Stop engine during rolling retest' || row.rollingRetestTimeoutIndex || row.state === 'Rolling retest is disconnected' || row.state === 'Rolling retest timeout'))))
      if (kind === 'tamper') setRows(allData.filter(row => row.tamperAttempt === true))
      if (kind === 'circumvention') setRows(allData.filter(row => row.circumventionAttempt === true))
      if (kind === 'dui') setRows(allData.filter(row => parseFloat(row.bac) > .079))
      if (kind === 'Test Request') setRows(allData.filter(row => (row.event === 'Test Request' || row.state === "Regular Blow")))
      if (kind === 'retestTimeout') {
        setRows(allData.filter(row => (row.state === 'Stop engine during rolling retest' || row.rollingRetestTimeoutIndex || row.state === 'Rolling retest is disconnected' || row.state === 'Rolling retest timeout')))
        
      }
    // }
    setPage(0)
    
    
  }
  return (
    <div className={classes.root}>
      <Dialog
          open={ showBigImage }
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullScreen
        >
          <DialogTitle id="alert-dialog-title">View Photos</DialogTitle>
          <DialogContent>
            <img src={ bigImageSource } style={{ width: '100%' }}></img>
          </DialogContent>
          <DialogActions>
            
            <Button onClick={(() => setShowBigImage(false))} color="secondary" autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      {/* <DownloadDetails data={ detailsToShow }></DownloadDetails> */}
      <DownloadDetailsModal data={ detailsToShow } open={ showDownloadDetails }></DownloadDetailsModal>
      <Paper className={classes.paper}>
      <Grid container className={ classes.filterArea }>
          <Grid item xs={3}>
          <a onClick={((e) => filterTable(e, null, 'all')) }>Total Records: {numberOfRecords || 0} </a>
          </Grid>
          <Grid item xs={3}>
          <a onClick={((e) => filterTable(e, null, 'Test Request')) }> Total Test Requests: {summary.passedTests + summary.failedTests + summary.retestsMissed  || 0} </a>
          </Grid>
          <Grid item xs={3}>
          <a onClick={((e) => filterTable(e, null, 'Pass')) }> Passed Tests: {summary.passedTests || 0} </a>
          </Grid>
          <Grid item xs={3}>
            <a onClick={((e) => filterTable(e, null, 'Fail')) }>Failed Tests: {summary.failedTests || 0} </a>
          </Grid>
          <Grid item xs={3}>
          <a onClick={((e) => filterTable(e, null, 'tamper')) }>Tamper Attempts: {summary.tamperAttempts || 0} </a>
          </Grid>
          <Grid item xs={3}>
          <a onClick={((e) => filterTable(e, null, 'circumvention')) }> Circumvention Attempts: {summary.circumventionAttempts || 0} </a>
          </Grid>
          <Grid item xs={3} id="resettimeout">
          <a onClick={((e) => filterTable(e, null, 'retestTimeout')) }> Re-Tests Missed: {summary.retestsMissed || 0} </a>
          </Grid>
          <Grid item xs={3} >
            <a onClick={((e) => filterTable(e, null, 'dui')) }> DUI Level Violations: {summary.duiLevel || 0} </a>
          </Grid>
        </Grid>
        <div className={classes.tableWrapper} style={{margin: '10px'}}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {filterOut(stableSort(rows, getSorting(order, orderBy)))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
               // console.log('Row map: ', row)
               // console.log('Created: ', row.created)
                  const isItemSelected = isSelected(row._id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                      selected={isItemSelected}
                    >
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        { row.line }
                      </TableCell>
                      <TableCell align="left">
                        {/* {JSON.stringify(row)} */}
                      { moment(row.myDate).format('ddd MMM DD, YYYY [at] hh:mma') } 
                      {/* { moment(row.timestamp).format('ddd MMM DD, YYYY [at] hh:mma') }  */}
                      </TableCell>
                      <TableCell align="left">
                        { row.event }
                      </TableCell>
                      <TableCell align="left">
                        { row.state }
                      </TableCell>
                      <TableCell align="left">
                        { row.bac }
                      </TableCell>
                      <TableCell align="left">
                        { row.unit }
                      </TableCell>
                      <TableCell align="left">
                        { row.photoURL ? <img onDoubleClick={ ((e) => openImage(row)) } style={{ width: '75px'}} src={ `${portalurl}/${row.photoURL}` }></img> : '' }
                      </TableCell>
                    </TableRow>
                  );
                })
              }
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 50, 100, 500, 1000]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
    </div>
  );
}
const mapStateToProps = (state) => {
  // console.log('Map: ', state)
  return {
    authenticated: state.authenticated,
    notification: state.notification,
    url: state.url,
    portalurl: state.portalurl
  }
}

export default connect(mapStateToProps)(EnhancedTable)

