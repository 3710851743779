import React, { Component } from 'react'
import styles from './registration.module.scss'
import { connect } from 'react-redux'
import {
  Paper,
  Typography,
  Button,
  Grid,
  CircularProgress
} from '@material-ui/core'
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator'
import axios from 'axios'

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      firstName: null,
      lastName: null,
      email: null,
      password: null,
      passwordAgain: null,
      loading: false,
      invitationId: null
    }
  }

  register = async () => {
    const { username, firstName, lastName, email, password, invitationId } = this.state
    const userdata = {
      username,
      firstName,
      lastName,
      email,
      password,
      invitationId
    }
    this.setState({
      loading: true
    })
    const response = await axios.put(`${this.props.url}/api/auth/registration`, userdata)
    this.setState({
      loading: false
    })
    if (response.data.success) {
      this.props.notification({
        type: 'success',
        title: 'Signed up!',
        message: 'Please Login Using Your Credentials!'
      })
      this.props.history.push('/')
    } else {
      this.props.notification({
        type: 'danger',
        title: 'Sign up failed!',
        message: 'Email already registered!'
      })
    }
  }

  handleChange = (event) => {
    const name = event.target.name
    const value = event.target.value
    this.setState({
      [name]: value
    })
  }

  componentDidMount() {
    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
      if (value !== this.state.password) {
        return false
      }
      return true
    })
    // console.log('Params', this.props.history)
    // let params = decodeURIComponent(window.location.search)
    // console.log(params)
    // console.log(window.location.search)
    // const queryParameters = new URLSearchParams(window.location.search)
    // // console.log('2', queryParameters)
    // // console.log('query Params', queryParametersEncoded)
    // // let queryParameters = decodeURIComponent(queryParametersEncoded)
    // console.log('queryParameters', queryParameters)
    const queryParameters = new URLSearchParams(decodeURIComponent(window.location.search))
    console.log('S', queryParameters)
    let name = queryParameters.get('firstName')
    console.log('name', name)
    // let oruEmail = queryParametersEncoded.get('email')
    // console.log('EMail;', oruEmail)
    // let celan = decodeURIComponent(oruEmail)
    // console.log('clean', celan)
    if (queryParameters) {
      console.log('Query params', queryParameters)
      const invitationId = queryParameters.get('invitationId') || null
      const firstName = queryParameters.get('firstName')
      console.log('first name', firstName)
      const email = queryParameters.get('email')
      this.setState({...this.state, firstName: firstName, invitationId: invitationId, email: email })
    }
  }

  render = () => {
    const { loading } = this.state
    return (
      <div className={ styles.registration }>
        <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
          <Paper>
            <Typography className={ styles.title } component="h1" variant="h5">
              Sign up
            </Typography>
            <ValidatorForm
              ref="form"
              onSubmit={ this.register }
              // onError={ errors => console.log(errors) }
              className={ styles.content }
            >
              <TextValidator
                label="Username"
                onChange={ this.handleChange }
                name="username"
                value={ this.state.username || '' }
                validators={[
                  'required',
                  'minStringLength:5',
                  'maxStringLength:100'
                ]}
                errorMessages={[
                  'this field is required',
                  'min 5 characters',
                  'max 100 characters'
                ]}
                margin="normal"
                fullWidth
                autoComplete="new-password"
              />
              <TextValidator
                label="First Name"
                onChange={ this.handleChange }
                name="firstName"
                value={ this.state.firstName || '' }
                validators={[
                  'required',
                  'minStringLength:2',
                  'maxStringLength:50'
                ]}
                errorMessages={[
                  'this field is required',
                  'min 2 characters',
                  'max 50 characters'
                ]}
                margin="normal"
                fullWidth
              />
              <TextValidator
                label="Last Name"
                onChange={ this.handleChange }
                name="lastName"
                value={ this.state.lastName || '' }
                validators={[
                  'required',
                  'minStringLength:2',
                  'maxStringLength:50'
                ]}
                errorMessages={[
                  'this field is required',
                  'min 2 characters',
                  'max 50 characters'
                ]}
                margin="normal"
                fullWidth
              />
              <TextValidator
                label="Email"
                onChange={ this.handleChange }
                name="email"
                value={ this.state.email || '' }
                validators={[
                  'required',
                  'isEmail',
                  'minStringLength:5',
                  'maxStringLength:100'
                ]}
                errorMessages={[
                  'this field is required',
                  'email is not valid',
                  'min 5 characters',
                  'max 100 characters'
                ]}
                margin="normal"
                fullWidth
                autoComplete="new-password"
              />
              <TextValidator
                label="Password"
                onChange={ this.handleChange }
                name="password"
                type="password"
                value={ this.state.password || '' }
                validators={[
                  'required',
                  'minStringLength:5',
                  'maxStringLength:100'
                ]}
                errorMessages={[
                  'this field is required',
                  'min 5 characters',
                  'max 100 characters'
                ]}
                margin="normal"
                fullWidth
                autoComplete="new-password"
              />
              <TextValidator
                label="Password again"
                onChange={ this.handleChange }
                name="passwordAgain"
                type="password"
                value={ this.state.passwordAgain || '' }
                validators={[
                  'required',
                  'isPasswordMatch',
                  'minStringLength:5',
                  'maxStringLength:100'
                ]}
                errorMessages={[
                  'this field is required',
                  'password mismatch',
                  'min 5 characters',
                  'max 100 characters'
                ]}
                margin="normal"
                fullWidth
                autoComplete="new-password"
              />
              {
                loading ?
                <Button
                  className={ this.button }
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled
                >
                  <CircularProgress color="primary" size={ 24 } />
                </Button> :
                <Button
                  type="submit"
                  className={ this.button }
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Sign up
                </Button>
              }
            </ValidatorForm>
          </Paper>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(App)
