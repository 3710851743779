import React, { Component } from 'react'
import styles from './home.module.scss'
import { Card, CardContent, Typography, CircularProgress, Button, Grid } from '@material-ui/core'
// import GitHubButton from 'react-github-btn'
import RegistrationFinish  from '../RegistrationFinish/RegistrationFinish'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import axios from 'axios'
import DuplicatesModal from './DuplicatesModal'

class Home extends Component {
  constructor(props) {
    super(props)
    console.log(props)
    if (!props.user || !props.user.id) {
      console.log('No user...')
      this.props.history.push('/login')
    }
    this.state = {
      dlNumber: '',
      firstName: '',
      lastName: '',
      loading: false,
      clients: [],
      duplicatesModalOpen: false
    }
  }

  handleChange = (event) => {
    // console.log('Handle change: ', event.target.name)
    // console.log(event.target.value)
    const name = event.target.name
    const value = event.target.value
    this.setState({
      [name]: value
    })
  }

  search = async (searchData) => {
    const { dlNumber, firstName, lastName } = this.state
    let data = {
      dlNumber,
      firstName,
      lastName
    }
    console.log(data)
    this.setState({
      loading: true
    })
    if (searchData && searchData.user) data = searchData
    const response = await axios.post(`${this.props.url}/api/auth/driversearch`, data)
    this.setState({
      loading: false
    })
    console.log('Response: ', response)
    if (response.data._id) {
      // console.log(response.data)
      this.props.notification({
        type: 'success',
        title: 'Success!',
        message: 'Profile Found!'
      })
      // console.log('State: ', response.data.stateReportTo)
      if (response.data.stateReportTo === 'Kansas') {
        this.props.history.push({
          pathname: '/customerdetailsks',
          state: { customer: response.data }
        })
      } else {
        this.props.history.push({
          pathname: '/customerdetails',
          state: { customer: response.data }
        })
      }
    } else if (response.data.clients && response.data.clients.length) {
      // console.log('Multiple Clients Match', response.data.clients)
      this.setState({...this.state, clients: response.data.clients, duplicatesModalOpen: true })
      this.props.notification({
        type: 'success',
        title: 'Multiple Matches Found!',
        message: `We found ${response.data.clients.length} ${response.data.clients.length > 1 ? 'matches' : 'match' } to your search.`
      })

    } else {
      this.props.notification({
        type: 'danger',
        title: 'Search Failed!',
        message: 'Are You Sure This is the Correct Driver Information?'
      })
    }
  }

  handleCloseDuplicatesModal = () => {
    this.setState({...this.state, duplicatesModalOpen: false })
  }

  handleChooseClient = (client) => {
    console.log('CHoose client', client)
    this.setState({...this.state, duplicatesModalOpen: false, loading: true, firstName: client.firstName, lastName: client.lastName, dlNumber: client.driverNumber })
    this.search({ firstName: client.firstName, lastName: client.lastName, dlNumber: client.driverNumber, user: this.props.user })
  }

  render() {
    const { user } = this.props
    return (
      <div className={ styles.home }>
        <DuplicatesModal clients={this.state.clients} open={this.state.duplicatesModalOpen} closeModal={this.handleCloseDuplicatesModal} chooseClient={this.handleChooseClient} />
        {
          user && !user.active ?
          <RegistrationFinish></RegistrationFinish> :
          null
        }
        <Card className={ styles.card }>
          <CardContent>
            <img src="https://www.clear2drive.com/wp-content/uploads/2021/08/logo.png" />
            <Typography color="textSecondary" gutterBottom>
              Welcome...{user && user.name ? user.name : ''}
            </Typography>
            <Typography variant="h5" component="h2" gutterBottom>
              C2D Systems Authority View
            </Typography>
            <Typography component="p">
              Please use this portal to view customer data/photographs/violations etc. by entering a Driver License Number below:
            </Typography>
            <ValidatorForm
              ref="form"
              onSubmit={ this.search }
              // onError={ errors => console.log(errors) }
              className={ styles.content }
            >
              {
                this.state.loading ?
                
                  <CircularProgress color="primary" size={ 24 } />
                 :
                 <Grid container spacing={2}>
                  <Grid item lg={6} xs={12} sm={6} md={6}>
                    <TextValidator
                      label="First Name"
                      onChange={ this.handleChange }
                      name="firstName"
                      value={ this.state.firstName || '' }
                      validators={[
                        'minStringLength:3',
                        'maxStringLength:100'
                      ]}
                      errorMessages={[
                        'min 3 characters',
                        'max 100 characters'
                      ]}
                      margin="normal"
                      fullWidth
                    />
                  </Grid>
                  <Grid item lg={6} xs={12} sm={6} md={6}>
                    <TextValidator
                      label="Last Name"
                      onChange={ this.handleChange }
                      name="lastName"
                      value={ this.state.lastName || '' }
                      validators={[
                        'minStringLength:3',
                        'maxStringLength:100'
                      ]}
                      errorMessages={[
                        'min 3 characters',
                        'max 100 characters'
                      ]}
                      margin="normal"
                      fullWidth
                    />
                  </Grid>
                  <Grid item lg={12} xs={12} sm={12} md={12}>
                    <TextValidator
                      label="Driver License Number"
                      onChange={ this.handleChange }
                      name="dlNumber"
                      value={ this.state.dlNumber || '' }
                      validators={[
                        'required',
                        'minStringLength:5',
                        'maxStringLength:100'
                      ]}
                      errorMessages={[
                        'this field is required',
                        'min 5 characters',
                        'max 100 characters'
                      ]}
                      margin="normal"
                      fullWidth
                    />
                  </Grid>
                  <Grid item lg={12} xs={12} sm={12} md={12}>
                    <Button
                      variant="contained"
                      fullWidth
                      color="primary"
                      onClick={this.search}
                      disabled={(!this.state.dlNumber && !this.state.firstName && !this.state.lastName)}
                    >Search</Button>
                  </Grid>
                </Grid>
              }
              
            </ValidatorForm>
           
          </CardContent>
        </Card>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  // console.log('Map to props: ', state)
  return {
    url: state.url,
    portalurl: state.portalurl,
    authenticated: state.authenticated,
    notification: state.notification,
    user: state.user
  }
}

export default withRouter(connect(mapStateToProps)(Home))
