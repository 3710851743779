// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".paypal_paypal__9RuPJ {\n  margin: 20px; }\n\n.paypal_button__2RJXo {\n  margin-top: 15px;\n  width: 200px; }\n\n.paypal_card__3Pgk6 {\n  width: 300px; }\n", ""]);
// Exports
exports.locals = {
	"paypal": "paypal_paypal__9RuPJ",
	"button": "paypal_button__2RJXo",
	"card": "paypal_card__3Pgk6"
};
module.exports = exports;
