import React from 'react'
import { connect } from 'react-redux'
import Paper from '@material-ui/core/Paper'
import styled from 'styled-components'
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Switch from '@material-ui/core/Switch';

const Container = styled.div`
  margin: 10px;
  color: #555;
  font-size: 18px;
  padding: 10px;
`;

const Item = styled.div`
  display: flex;
`;

const RolesContainer = styled(Box)`
  display: flex;
  margin-top: 25px;
  margin-bottom: 15px;
`;

const RolesList = styled(Box)`
  display: flex;
  flex-direction: column;
  font-size: 16px;
`;

const PermissionsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  width: 50%
`;

const StyledRow = styled(TableRow)`
  padding: 15px;
  border: none;
  margin: 15px;
  min-height: 20px;
`;

const StyledCell = styled(TableCell)`
  padding: 15px;
  border: none;
  margin: 15px;
  min-height: 20px;
`;

const Permissions = (props) => {
  // console.log('Permission Details: ', props)

  const initialPermissions = [
    {
      type: 'Customers',
      view: false,
      update: false,
      delete: false
    },
    {
      type: 'Users',
      view: false,
      update: false,
      delete: false
    }
  ]

  const [state, setState] = React.useState({
    permissions: initialPermissions,
  })

  React.useEffect(() => {
    // console.log('Permissiosn Use Effect')
    // console.log('Initial Perms Length: ', initialPermissions.length)
    console.log(initialPermissions)
    if (props.profile && props.profile.permissions && props.profile.permissions.length) {
      // console.log('Set Profile Permissions: ', props.profile.permissions)
      setState({...state, permissions: props.profile.permissions })
      if (props.profile.permissions.length < initialPermissions.length) {
        // console.log('Fix this guy', props.profile)
        let perms = props.profile.permissions
        for (const perm of initialPermissions) {
          console.log(perm)
          let index = perms.findIndex(i => (i.type === perm.type))
          if (index > -1) {

          } else {
            // console.log('Need to add this perm', perm.type)
            perms.push(perm)
          }
        }
        setState({...state, permissions: perms })
      }
    }
  },[props])

  const handleChange = (row, type, index) => {
    // console.log('Handle CHange', row)
    // console.log('Type: ', type)

    // const id = event.target.id
    // console.log(id)
    // let int = id.split('-')[1]
    // console.log(int)
    let perms = state.permissions
    perms[index][type] = !state.permissions[index][type]
    setState({ ...state, permissions: perms })
    props.updateParent(state.permissions)
  }

  return (
    <div>
      <Container>
        <RolesList>
        <Table
            aria-labelledby="tableTitle"
            size='small'
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell>View</TableCell>
                <TableCell>Update</TableCell>
                <TableCell>Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { state.permissions.map((row, index) => {
                  return (
                    <StyledRow
                      hover
                      tabIndex={-1}
                      key={index}
                      // onDoubleClick={() => handleEditWo(row)}
                    >
                      <StyledCell component="td" id={row._id} scope="row" padding="none">
                        {row.type}
                      </StyledCell>
                      <StyledCell component="td"  scope="row" padding="none">
                        <Switch
                          checked={row.view}
                          onChange={() => handleChange(row, 'view', index)}
                          name={row.type}
                          id={`switch-${index}`}
                          inputProps={{ 'aria-label': 'checkbox' }}
                        />
                        
                      </StyledCell>
                      <StyledCell component="td" scope="row" padding="none">
                        <Switch
                            checked={row.update}
                            onChange={() => handleChange(row, 'update', index)}
                            name={row.type}
                            id={`switch-${index}`}
                            inputProps={{ 'aria-label': 'checkbox' }}
                          />
                      </StyledCell>
                      <StyledCell component="td"scope="row" padding="none">
                        <Switch
                            checked={row.delete}
                            onChange={() => handleChange(row, 'delete', index)}
                            name={row.type}
                            id={`switch-${index}`}
                            inputProps={{ 'aria-label': 'checkbox' }}
                          />
                      </StyledCell>
                     
                      
                    </StyledRow>
                  )})
              }
            </TableBody>
          </Table>
        </RolesList>
      </Container>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(Permissions)