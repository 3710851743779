import React from 'react'
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import axios from 'axios'
import { connect } from 'react-redux'
import { fade, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Chip from '@material-ui/core/Chip';
// import AdbIcon from '@material-ui/icons/Adb';
import { Button, CircularProgress }  from '@material-ui/core';
import Grid from '@material-ui/core/Grid'
// import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';
// import Popover from '@material-ui/core/Popover';
import Paper from '@material-ui/core/Paper';
// import Checkbox from '@material-ui/core/Checkbox';
// import IconButton from '@material-ui/core/IconButton';
// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
// import AddIcon from '@material-ui/icons/AddCircle';
// import FilterListIcon from '@material-ui/icons/FilterList';
import moment from 'moment'
import { Link } from 'react-router-dom'
// import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import ViewIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
// import CustomerDetailsModal from './CustomerDetail'
// import AddCustomerModal from './AddCustomerModal'
// import { socket } from '../SocketIo/Socket'
// import FormGroup from '@material-ui/core/FormGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
// function createData(name, status, created, lastactivity, tourbooked) {
//   return { name, status, created, lastactivity, tourbooked };
// }

// const rows = [
//   createData('Tom Smith', 'New', '12/17/2019', null, null),
//   createData('Donut', 'Working', '12/18/2019', 51, 4.9),
//   createData('Eclair', 'New', '11/29/2019', 24, 6.0),
//   createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//   createData('Gingerbread', 356, 16.0, 49, 3.9),
//   createData('Honeycomb', 408, 3.2, 87, 6.5),
//   createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//   createData('Jelly Bean', 375, 0.0, 94, 0.0),
//   createData('KitKat', 518, 26.0, 65, 7.0),
//   createData('Lollipop', 392, 0.2, 98, 0.0),
//   createData('Marshmallow', 318, 0, 81, 2.0),
//   createData('Nougat', 360, 19.0, 9, 37.0),
//   createData('Oreo', 437, 18.0, 63, 4.0),
// ];

function desc(a, b, orderBy, isDate) {
  // console.log('desc: ', a)
  // console.log('desc b: ', b)
  if (isDate) {
    if (b[orderBy] < a[orderBy] || b[orderBy] == null) {
      // console.log('B is < A')
      return -1;
    }
    if (b[orderBy] > a[orderBy] || a[orderBy] == null) {
      // console.log('B is > A')
      return 1;
    }
    // console.log('B is ? A')
    // console.log('A: ', a[orderBy])
    // console.log('B: ', b[orderBy])
    return 0;
  } else {
    if (b[orderBy] < a[orderBy] || b[orderBy] == null) {
      return -1;
    }
    if (b[orderBy] > a[orderBy] || a[orderBy] == null) {
      return 1;
    }
    return 0;
  }
}

function stableSort(array, cmp) {
  console.log('Stable sort: ', array.length)
  // console.log('CMP: ', cmp)
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  // console.log('Matches: ', stabilizedThis.length)
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  if (orderBy==='lastUpdated') {
    console.log('Last Updated')
    return order === 'desc' ? (a, b) => desc(a, b, orderBy, true) : (a, b) => -desc(a, b, orderBy, true);
  } else {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }
  
}

const headCells = [
  { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'username', numeric: false, disablePadding: false, label: 'Username' },
  { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
  { id: 'active', numeric: false, disablePadding: false, label: 'Active' },
  { id: 'assignedState', numeric: false, disablePadding: false, label: 'Assigned State' },
  { id: 'updated_at', numeric: false, disablePadding: false, label: 'Last Update' },

];

function EnhancedTableHead(props) {
  console.log('Got our props', props)
  
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    console.log('Sort Property: ', property)
    console.log('sort event; ', event)
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all leads' }}
          />
        </TableCell> */}
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    margin: '15px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fakeLink: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: 'blue'
  },
  rolesContainer: {
    width: '100%',
    display: 'flex',
    alignSelf: 'stretch',
    borderRadius: 20,
    border: '2px solid #ddd',
    flexDirection: 'column',
    backgroundColor: '#eee',
    overflow: 'hidden',
  },
  rolesArea: {
    width: '100%',
    display: 'flex',
    alignSelf: 'stretch',
    // borderRadius: 20,
    // border: '1px solid #ccc',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  rolesItem: {
    display: 'flex',
    fontSize: 12,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 5
  },
  permsPreview: {
    zIndex: 999,
    border: '3px solid red'
  },
  permissionDetailsContainer: {
    zIndex: 999,
    border: '3px solid #eee',
    borderRadius: 20,
    // backgroundColor: '#fff',
    // margin: 15,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignSelf: 'stretch',
    textAlign: 'center'
  },
  permissionDetails: {
    // zIndex: 999,
    border: '3px solid #eee',
    borderRadius: 20,
    color: '#555',
    backgroundColor: '#fff',
    margin: 15,
    marginTop: 1,
    display: 'flex',
    alignSelf: 'stretch'
  }
}));

function UserListTable(props) {
  console.log('User List Props: ', props)
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('-created');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [searchValue, setsearchValue] = React.useState('');
  const [searchMatches, setsearchMatches] = React.useState(0);
  const [customerDetailModalOpen, setCustomerDetailModalOpen] = React.useState(false)
  // const [snoutWeevilModalOpen, setSnoutWeevilModalOpen] = React.useState(false)
  const [client, setClient] = React.useState({});
  const [loading, setLoading] = React.useState(false)
  // const [showAllCustomers, setShowAllCustomers] = React.useState(false)
  const [filteredRows, setFilteredRows] = React.useState([])
  const [state, setState] = React.useState({})
  const [showNewClientModal, setShowNewClientModal] = React.useState(false)
  const [showPermsPreview, setShowPermsPreview] = React.useState(null)
  const [rows, setRows] = React.useState(props.clients || []);

  async function fetchData(toReturn) {
    console.log('Fetch our Users')
    setLoading(true)
    const response = await axios.get(`${props.url}/api/user/listAll`)
    console.log(response)
    if (response.data && response.data.length > 0) {
      console.log('Got Users!!')
      setRows(response.data)
      setFilteredRows(response.data)
    } else {
      console.log('Fucked up')
    }
  }

  React.useEffect(() => {

    fetchData()
  }, [])

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    console.log('Set order by property: ' + property + ' in ' + isDesc + ' order')
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n._id);
      // console.log(newSelecteds)
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  //   console.log('handle click ', name)
  //   console.log('Index: ', selectedIndex)
  //   let newSelected = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1),
  //     );
  //   }

  //   setSelected(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const storeSearchMatches = (d) => {
    console.log('Search matches: ', d)
    // setState({...state, searchMatches: d.length})
  }
  // const handleChangeDense = event => {
  //   setDense(event.target.checked);
  // };

  const isSelected = name => selected.indexOf(name) !== -1;

  const emptyRows = 0// rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const filterOut = (r, searchText) => {
    console.log('FIlter out: %s - %s', r, searchText)
    let string = searchText
    let newArr = []
    r.filter(o => {
      // console.log('Object:  ', o)
      Object.keys(o).some(k => {
        // console.log(o[k])
        if(o[k]) {
          // console.log('OK: ', o[k])
          let str = o[k].toString().toLowerCase() 
          // console.log(str)
          if (str.includes(string.toLowerCase())) {
            // console.log('Mathc')
            return newArr.push(o)
          } else {
            return null
          }
        } else {
          return null
        }
      })
      // return newArr
    })
    // return newArr
    // console.log('Lenght: ', newArr.length)
    // console.log(newArr)
    // storeSearchMatches(newArr)
    // setsearchMatches(newArr.length)
    // return newArr
    setFilteredRows(newArr)
    // let newArr = r.filter(i => {
    //   if (i.includes('test')) return i
    // })
    // return newArr
    // return r.filter((obj)=>{
    //   return Object.keys(obj).reduce((acc, curr)=>{
    //         return acc || obj[curr] ? obj[curr].toString().toLowerCase().includes(string) : obj[curr];
    //   }, false);
    // })
  }
  const handleChange = (name) => (event) => {
    console.log('Searching: ', event.target.value)
    setsearchValue(event.target.value)
    if (event.target.value === '') {
      setFilteredRows(rows)
    } else {
      filterOut(rows, event.target.value)
    }
    setPage(0)
  }
  // async function fetchData() {
  //   console.log('Update our clients')
  //   const response = await axios.get(`${props.url}/api/customer/clients`)
  //   console.log(response)
  //   if (response.data && response.data.length > 0) {
  //     console.log('Got Updated Clients!!')
  //     setFilteredRows(response.data)
  //     setRows(response.data)
  //   } else {
  //     console.log('Fucked up getting Clients')
  //   }
  // }

  const openClientModal = (client) => {
    console.log('Open client modal: ', client)
    setClient(client)
    setCustomerDetailModalOpen(true)
    // props.updateCustomerList(clients => {
    //   console.log('Got updated client list!', clients)
    //   console.log('SHOW AL: ', showAllCustomers)
    //   if (showAllCustomers) {
    //     console.log('Show everyone')
    //     setRows(clients)
    //     filterOut(clients, searchValue)
    //   } else {
    //     console.log('Gonna just show the active clients')
    //     setRows(clients)
    //     let activeClients = clients.filter(item => { return item.active })
    //     filterOut(activeClients, searchValue)
    //   }
    // })
  }
  
  const onModalClose = (toClose) => {
    console.log('Modal close...', toClose)
    // setClient(null)
    setClient({})
    setCustomerDetailModalOpen(false)
    setLoading(false)
    props.updateCustomerList()
    //  => {
    //     console.log('Got updated client list!', clients)
    //     setLoading(false)
      //   console.log('SHOW AL: ', showAllCustomers)
      //   if (showAllCustomers) {
      //     console.log('Show everyone')
      //     setRows(clients)
      //     filterOut(clients, searchValue)
      //   } else {
      //     console.log('Gonna just show the active clients')
      //     setRows(clients)
      //     let activeClients = clients.filter(item => { return item.active })
      //     filterOut(activeClients, searchValue)
      //   }
      // })
  }

  const handleSnoutWeevil = (client) => {
    console.log('Open Snout Weevil Modal')
    setClient(client)
    setState({...state, openSnoutWeevil: true})
    // setSnoutWeevilModalOpen(true)
    setCustomerDetailModalOpen(true)
  }
  const onSWModalClose = (d) => {
    console.log('Close snout weevil modal', d)
    setState({...state, openSnoutWeevil: false})
    // setSnoutWeevilModalOpen(false)
    setCustomerDetailModalOpen(false)
  }
  const updateParent = (d) => {
    console.log('Update from child ', d)
    if (d && d === 'onSWModalClose') onSWModalClose(d)
    if (d && d.client) {
      console.log('Update our client list with this guy: ', d.client)
      setShowNewClientModal(false)
      let clients = rows
      // console.log('Got %d clients: ', clients.length)
      let index = clients.findIndex(client =>  (client._id === d.client._id))
      if (index > -1) {
        console.log('Client already exists')
      } else {
        clients.push(d.client)
        setRows(clients)
        setFilteredRows(clients)
      }
      // let ourClient = clients[index]
      // console.log('Our client: ', ourClient)
      // clients.splice(index, 1)
      // console.log(rows)
      // console.log('Filtered Rows: ', filteredRows)
    }
  }

const handleAddUser = () => {
  console.log('Add new User')

}

const handlePreviewOfPermissions = (e, perms, user) => {
  // console.log('Display the perms now!!', perms)
  console.log(user.name)
  setShowPermsPreview(user._id)
}

const handleHidePreview = () => {
  setShowPermsPreview(null)
}

const PermissionDetails = ({ perms, user }) => {
  let viewPerms = (
    <div className={classes.permissionDetailsContainer}>
    <div style={{fontSize: 22, fontWeight: '800', color: '#333'}}>{user.name}</div>
      <div className={classes.permissionDetails}>
        
          <div className={classes.rolesArea} >
          
            <div className={classes.rolesItem}>
            <ViewIcon />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;View:
              <ul>
                {perms.map(item => (
                  item.view ? <li>{item.type}</li> : null
                  ))}
              </ul>
            </div>
            <div className={classes.rolesItem}>
              <EditIcon />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Modify:
              <ul>
                {perms.map(item => (
                  item.update ? <li>{item.type}</li> : null
                  ))}
              </ul>
            </div>
            <div className={classes.rolesItem} >
              <DeleteForeverIcon />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Delete:
              <ul>
                {perms.map(item => (
                  item.delete ? <li>{item.type}</li> : null
                  ))}
              </ul>
            </div>
          </div>
      </div>
    </div>
  )
  return viewPerms
}

const PermissionList = ({ perms, user }) => {
  // console.log('Get permissions: ', perms)
  // console.log("User: ", user)
  if (perms && perms.length) {
    let numView = 0
    let numUpdate = 0
    let numDelete = 0
    perms.map(item => {
      if (item.view) numView++
      if (item.update) numUpdate++
      if (item.delete) numDelete++
    })
    return (
      <div key={user._id} className={classes.rolesContainer} onMouseLeave={handleHidePreview} onMouseEnter={e => handlePreviewOfPermissions(e, perms, user)} >
        {
          (showPermsPreview && showPermsPreview === user._id) ? (

              <PermissionDetails perms={perms} user={user}  />
            
          ) : (
            <div className={classes.rolesArea} >
            <div className={classes.rolesItem}>
            <ViewIcon />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;View:  {numView}
            </div>
            <div className={classes.rolesItem}>
              <EditIcon />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Modify: {numUpdate}
            </div>
            <div className={classes.rolesItem} >
              <DeleteForeverIcon />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Delete: {numDelete}
            </div>
          </div>
          )
        }
      </div>
    )
  } else {
    return 'Got none'
  }
}


  return (
    <div className={classes.root}>
      <h1>Users</h1>
      <Paper className={classes.paper}>
        <Grid container style={{padding: '10px'}}>
          <Grid item lg={3} xs={6}>
          Total Users: {filteredRows.length}
          </Grid>
          <Grid item lg={3} xs={6}>
          {/* <Button
          variant="contained"
          // style="secondary"
          style={{ color: '#222'}}
          // className={classes.button}
          size="small"
          startIcon={<AddIcon  color="primary" />}
          // onClick={handleSnoutWeevil }
          onClick={handleAddUser}
          // onClick={handleDeleteService}
          // onClick={offerDeleteOption}
          // onClick={ () => { window.confirm('Are you sure you wish to delete this item?') ? handleDeleteService() : null } }
        >
          Invite User
        </Button>  */}
            {/* <IconButton onClick={handleAddClient} label="Add Client" edge='end' alt="Add New Client" aria-label="Add Client">
              <AddIcon color="primary" />
            </IconButton> */}
          </Grid>
        </Grid>
        <Grid container style={{padding: '10px'}}>
          <Grid item lg={6} xs={6}>

            <div className={classes.search}>
                <div >
                  <SearchIcon /> Search { searchValue ? filteredRows.length > 1 ? `(${filteredRows.length} Matches)` : `(${filteredRows.length} Match)` : ''}
                </div>
                <input name="search" value={ searchValue } onChange={ handleChange('search') } type="text"></input>
              </div>
          </Grid>

        </Grid>
        <div className={classes.tableWrapper} style={{margin: '10px'}}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              { stableSort(filteredRows, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  // console.log('Row map: ', row)
                  // console.log('Total rows; ', rows)
                  const isItemSelected = isSelected(row._id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  // setsearchMatches(rows.length)
                  // console.log(row)
                  return (
                    
                    <TableRow
                      hover
                      // onClick={event => handleClick(event, row._id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row._id}
                      selected={isItemSelected}
                      style={row.pastDue ? { backgroundColor: 'rgba(233, 103, 103, .5)', } : null}
                    >
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                       
                      <Link to={{
                          pathname: '/users/' + row._id,
                          // search: '?sort=name',
                          // hash: '#the-hash',
                          state: {profile: row, from: 'UserList'}
                          
                        }}
                        style={{ color: 'blue' }}
                        > { row.name + '' }
                        
                      </Link>
                      </TableCell>
                     
                      <TableCell align="left">
                        {/* { moment(row.created_at).format('MM/DD/YYYY - hh:mmA') } */}
                        { row.username }
                      </TableCell>
                      <TableCell align="left">{ row.email } </TableCell>
                      
                      <TableCell align="left">
                        {row.active ? <Chip style={{color: '#fff', background: 'rgba(90, 219, 10, 0.66)' }} size="small" label="Active" />
                        : <Chip style={{color: '#fff', background: 'rgba(219, 10, 10, 0.66)' }} size="small" label="Inactive" />
                        }
                      </TableCell>
                      <TableCell>
                          { row.assignedState ? row.assignedState.name : 'None' }
                      </TableCell>
                      <TableCell align="left">
                        { row.updated_at ? moment(row.updated_at).fromNow() : '' }
              
                      </TableCell>
                    </TableRow>
                  );
                })
              }
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          
                    
        </div>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50, 100, 500, 1000, 2000]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    authenticated: state.authenticated,
    notification: state.notification,
    url: state.url
  }
}

export default connect(mapStateToProps)(UserListTable)

